import 'intersection-observer';

const options = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "-50% 0px -50% 0px", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
};

const observerAbout = new IntersectionObserver(AboutDoWhenIntersect, options);

const AboutBoxes = document.querySelectorAll("#about");
//IE11対策
const AboutBoxesArr = Array.prototype.slice.call(AboutBoxes);
AboutBoxesArr.forEach(box => {
    observerAbout.observe(box);
});

function AboutDoWhenIntersect(entries) {
    //IE11対策
    const entriesArr = Array.prototype.slice.call(entries);
    const nav = document.querySelectorAll("#navigation li");
    
    entriesArr.forEach(entry => {
        if (entry.isIntersecting) {
            nav.forEach(item => {
                item.classList.remove("current")
            })
            const aboutli = document.querySelectorAll("#navigation .aboutli");
            aboutli.forEach(aboutlist => {
                aboutlist.classList.add("current");
            })
        }else{
            const aboutli = document.querySelectorAll("#navigation .aboutli");
            aboutli.forEach(aboutlist => {
                aboutlist.classList.remove("current");
            })
        }
    });
}




const observerAccess = new IntersectionObserver(AccessDoWhenIntersect, options);

const AccessBoxes = document.querySelectorAll("#access");
//IE11対策
const AccessBoxesArr = Array.prototype.slice.call(AccessBoxes);
AccessBoxesArr.forEach(box => {
    observerAccess.observe(box);
});

function AccessDoWhenIntersect(entries) {
    //IE11対策
    const entriesArr = Array.prototype.slice.call(entries);
    const nav = document.querySelectorAll("#navigation li");
    
    entriesArr.forEach(entry => {
        if (entry.isIntersecting) {
            const aboutli = document.querySelectorAll("#navigation .accessli");
            aboutli.forEach(aboutlist => {
                aboutlist.classList.add("current");
            })
        }else{
            const aboutli = document.querySelectorAll("#navigation .accessli");
            aboutli.forEach(aboutlist => {
                aboutlist.classList.remove("current");
            })
        }
    });
}




const observerPrice = new IntersectionObserver(PriceDoWhenIntersect, options);

const PriceBoxes = document.querySelectorAll("#price");
//IE11対策
const PriceBoxesArr = Array.prototype.slice.call(PriceBoxes);
PriceBoxesArr.forEach(box => {
    observerPrice.observe(box);
});

function PriceDoWhenIntersect(entries) {
    //IE11対策
    const entriesArr = Array.prototype.slice.call(entries);
    const nav = document.querySelectorAll("#navigation li");
    
    entriesArr.forEach(entry => {
        if (entry.isIntersecting) {
            const aboutli = document.querySelectorAll("#navigation .priceli");
            aboutli.forEach(aboutlist => {
                aboutlist.classList.add("current");
            })
        }else{
            const aboutli = document.querySelectorAll("#navigation .priceli");
            aboutli.forEach(aboutlist => {
                aboutlist.classList.remove("current");
            })
        }
    });
}




const observerReserve = new IntersectionObserver(ReserveDoWhenIntersect, options);

const ReserveBoxes = document.querySelectorAll("#reserve");
//IE11対策
const ReserveBoxesArr = Array.prototype.slice.call(ReserveBoxes);
ReserveBoxesArr.forEach(box => {
    observerReserve.observe(box);
});

function ReserveDoWhenIntersect(entries) {
    //IE11対策
    const entriesArr = Array.prototype.slice.call(entries);
    const nav = document.querySelectorAll("#navigation li");
    
    entriesArr.forEach(entry => {
        if (entry.isIntersecting) {
            const aboutli = document.querySelectorAll("#navigation .reserveli");
            aboutli.forEach(aboutlist => {
                aboutlist.classList.add("current");
            })
        }else{
            const aboutli = document.querySelectorAll("#navigation .reserveli");
            aboutli.forEach(aboutlist => {
                aboutlist.classList.remove("current");
            })
        }
    });
}
